:root {
  --light-background: #f0f2f5;
  --light-foreground: #333;
  --dark-background: #1a1a2e;
  --dark-foreground: #e0e0e0;
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
}

body {
  margin: 0;
  font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  transition: all 0.3s ease;
}

.theme-toggle {
  position: fixed;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  z-index: 1000;
  transition: all 0.3s ease;
}

button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

a {
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 5px;
  transition: all 0.3s ease;
}

a:hover {
  opacity: 0.8;
}

/* Light Theme */
.light-theme {
  background-color: var(--light-background);
  color: var(--light-foreground);
}

.light-theme button {
  background-color: var(--primary-color);
  color: white;
}

.light-theme a {
  color: var(--primary-color);
}

.light-theme .theme-toggle {
  color: var(--dark-background);
}

/* Dark Theme */
.dark-theme {
  background-color: var(--dark-background);
  color: var(--dark-foreground);
}

.dark-theme button {
  background-color: var(--secondary-color);
  color: var(--dark-background);
}

.dark-theme a {
  color: var(--secondary-color);
}

.dark-theme .theme-toggle {
  color: var(--light-background);
}

.dark-theme h1 {
  color: var(--secondary-color);
}

/* Responsive design */
@media (max-width: 768px) {
  .app {
    flex-direction: column;
  }

  .main-content {
    padding: 10px;
  }

  .theme-toggle {
    top: 10px;
    right: 10px;
  }
}