.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #333;
  z-index: 10;
  padding-right: 60px; /* Adjust based on the size of your toggle theme button */
  display: flex;
  justify-content: space-between; /* Adjust content spacing */
  align-items: center;
  font-size: 18px;
}

.navbar-section, .navbar-actions {
  display: flex;
}

.navbar a, .navbar button {
  color: #fff;
  text-decoration: none;
  padding: 10px;
  margin-right: 10px;
  border-radius: 4px;
  background-color: transparent; /* Ensure buttons blend with navbar */
}

.navbar a:hover, .navbar button:hover {
  background-color: #555;
}

.tutorials-dropdown {
  display: none; /* Hidden by default */
  position: absolute;
  top: 100%;
  left: 120px; /* Adjust if necessary to position correctly */
  background-color: #444;
  padding: 10px 0;
  border-radius: 4px;
  width: 100%; /* Ensure it spans the full navbar width or adjust as needed */
}

.tutorials-dropdown a {
  padding: 10px 20px;
  display: block; /* Ensure dropdown items don't overlap */
}

.is-expanded .tutorials-dropdown {
  display: flex;
  flex-direction: column; /* Stack dropdown items vertically */
}
